.about-page {

.text-area {
    // margin: auto;
    // display: flex;
    position: absolute;
    left: 10%;
    // top: 50%;
    // transform: translateY(-50%);
    // width: 40%;
    max-height: 90%;
    width: 30%;
}

h1 {
    color: white;
    font-family: 'Coolvetica';
    font-size: 60px;
    margin-bottom: 15px;
    font-weight: 400;
    margin-top: 0;
    // position: relative;
    left: 10px;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    //     font-size: 18px;
    //     opacity: 0.6;
    //     margin-top: -10px;
    //     left: -10px;
    //     line-height: 18px;
    // }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    //     font-size: 18px;
    //     left: -30px;
    //     opacity: 0.6;
    //     margin-top: -10px;
    //     line-height: 18px;
    //     bottom: -20px;
    //     margin-left: 20px;
    // }
}

p {
    font-size: 22px;
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 400;
    min-width: fit-content;
    animation: pulse 1.5s;

    &:nth-of-type(1) {
        animation-delay: 1s;
    }

    &:nth-of-type(2) {
        animation-delay: 1.3s;
    }
    
    &:nth-of-type(3) {
        animation-delay: 1.6s;
    }
}



.UT-tag {
    color: #f98c3d;
}

.UT-img {
    position: relative;
    border: 5px solid #000000;
    border-radius: 5%;
    width: 20%;
    // top: 30%;
    margin-top: 105px;
    height: 40%;
    left: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: all 0.5s ease-in-out;
    }
}

.image-area {
    position: absolute;
    right: 5%;
    top: 12%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    
    .boy-scout {
        border: 5px solid #000000; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &:hover {
            cursor: pointer;
            transform: scale(1.01);
            transition: all 0.5s ease-in-out;
        }
    }


    @media (max-width: 1500px) {
        .boy-scout {
          width: 300px;
          height: 400px;
        }
    }

    
    @media (max-width: 1300px) {
        .boy-scout {
          width: 225px;
          height: 400px;
        }
    }
        
    @media (max-width: 970px) {
        .boy-scout {
          width: 190px;
          height: 400px;
        }
    }
    
    @media (max-width: 850px) {
        .boy-scout {
          right: 5%;
          width: 150px;
          height: 400px;
        }
    }

    @media (max-width: 768px) {
        .boy-scout {
          width: 200px;
          height: 150px;
        }
      }
}

.stage-cube-cont {
    width: 30%;
    height: 50%;
    top: 50%;
    padding-top: 10%;
    margin-left: 0;
    position: absolute;
    right: 25%;
    overflow: hidden;
}

.cubeSpin {
    animation-name: spinCube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #00B4D8;
        background-color: rgba(255, 255, 255, 0.739);
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px #00B4D8;
    }

    .sidePy {
        transform: translateZ(100px);
    }

    .sideHTML {
        transform: rotateY(90deg) translateZ(100px);
    }
    
    .sideCSS {
        transform: rotateY(180deg) rotate(90deg) translateZ(100px);
    }

    .sideReact {
        transform: rotateY(270deg) translateZ(100px);
    }

    .sideJS {
        transform: rotateX(90deg) rotate(-90deg) translateZ(100px);
    }

    .sideNode {
        transform: rotateX(-90deg) rotate(-90deg)translateZ(100px);
    }
}

@keyframes spinCube {
    from, 
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    25% {
        transform:  rotateY(-90deg);
    }
    33% {
        transform:  rotateY(-90deg) rotateZ(90deg);
    }
    50% {
        transform:  rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform:  rotateY(-270deg) rotateZ(90deg);
    }
    83% {
        transform:  rotateY(90deg);
    }
}

}
// @keyframes spinCube {
//     from,
//     to {
//       transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
//     }
//     16% {
//       transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg);
//     }
//     33% {
//       transform: rotateY(-90deg) rotateZ(90deg);
//     }
//     50% {
//       transform: rotateY(-180deg) rotateZ(90deg);
//     }
//     66% {
//       transform: rotateY(-270deg) rotateX(90deg);
//     }
//     83% {
//       transform: rotateX(90deg);
//     }
//   }