html {
  font-size: 60%;
}

body {
  margin: 0;
  font: 300 11px/1.4 "Helvetica Neue", 'sans-serif';
  color: #333;
  /* background-color: #2D3142; */
  /* background-color: #4F5D75; */

  /* background-color: #12425c; */
  background-color: #1c81b4;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}