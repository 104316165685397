.projects-page {
    .text-area {
        // background-color: green;
        position: absolute;
        left: 10%;
        top: 47%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            color: white;
            font-family: 'Coolvetica';
            font-size: 60px;
            margin-bottom: 15px;
            font-weight: 400;
            margin-top: 0;
            // position: absolute;
            left: 10px;

            &::before {
                content: '';
                position: absolute;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size: 18px;
                opacity: 0.6;
                margin-top: -10px;
                left: -10px;
                line-height: 18px;
            }

            &::after {
                content: '';
                position: absolute;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size: 18px;
                left: -30px;
                opacity: 0.6;
                margin-top: -10px;
                line-height: 18px;
                bottom: -20px;
                margin-left: 20px;
            }
        }

        .top-p {
            font-size: 20px;
            color: rgb(237, 247, 253);
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-weight: 400;
            // min-width: fit-content;
            animation: pulse 1.5s;
        }
    
        h2 {
            color: #fff;
            margin: 0;
            font-size: 40px;
            font-family: 'Coolvetica';
            letter-spacing: 2px;            
            font-weight: 400;
            min-width: fit-content;
            animation: pulse 1.5s;

            &:nth-of-type(1) {
                animation-delay: 1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.3s;
            }
            
            &:nth-of-type(3) {
                animation-delay: 1.6s;
            }

            &:nth-of-type(4) {
                animation-delay: 1.9s;
            }

            &:nth-of-type(5) {
                animation-delay: 2.2s;
            }
        }

        p {
            margin: 10px;
            color: #8be7f7;
            font-size: 18px;
            // color: white;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-weight: 200;
            min-width: fit-content;
            animation: pulse 1.5s;

            &:nth-of-type(1) {
                animation-delay: 1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.3s;
            }
            
            &:nth-of-type(3) {
                animation-delay: 1.6s;
            }

            &:nth-of-type(4) {
                animation-delay: 1.9s;
            }

            &:nth-of-type(5) {
                animation-delay: 2.2s;
            }
        }
    }


    .github-icons {
        position: absolute;
        top: 87%;
        left: 1%;
        width: 23%;
        display: block;
        padding: 0;
        // text-align: center;
        margin: 0;
        font-size: 35px;
        line-height: 16px;
        color: black;

        &:hover svg {
            color: #0077B6;
        }
    }
    
    .password-gen-github {
        position: absolute;
        top: 87%;
        left: 1%;
        width: 23%;
        display: block;
        padding: 0;
        // text-align: center;
        margin: 0;
        font-size: 35px;
        line-height: 16px;
        color: white;

        &:hover svg {
            color: #0077B6;
        }
    }

    .text-animate-hover {
        &:hover {
            animation: rubberBand 0.5s;
            color: #00B4D8;
            cursor: pointer;
        }
    }

    .project-area {
        position: absolute;
        right: 5%;
        width: 45%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px; 

        .project-1, .project-2, .project-3, .project-4 {
            position: relative;
            // display: block;
            width: 100%;
            height: 70%;
            margin-top: 60px;
            border: 3px solid white;
            border-radius: 5%;
            overflow: hidden;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            // object-fit: none;


          

                &:hover {
                    cursor: pointer;
                    transform: scale(1.03);
                    transition: all 0.5s ease-in-out;
            }
        }
    }

 
    // copilot write code that will make the .hide class appear when the .project-1 class is hovered over

      

    // .hide {
    //     position: absolute;
    //     opacity: 0;
    //     background-color: red;
    //     color: black;
    //     font-size: 20px;
    //     cursor: pointer;
      
    //     &.project-1 {
    //       &:hover {
    //         &.hide {
    //           opacity: 1;
    //         }
    //       }
    //     }
    //   }
}