.nav-bar {
    // background: #EF8354;
    // background: #1D8A99;
    // background-color: #0077B6;
    // background-color: #d3f1fb;
    background-color: #082d3a;


    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    // min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 4px;
            width: 60px;
            height: auto;
        }
    }


    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #cbd0d2;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #0077B6;
                // color: #CAF0F8;
                font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                

                svg {
                    opacity: .5;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                font-size: 10px; 
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: "HOME";
                }
            }
        }

            a.about-link {
                &::after {
                    content: "ABOUT ME"
                }
            }

            a.projects-link {
                &::after {
                    content: "PROJECTS" 
                }
            }

            a.contact-link {
                &::after {
                    content: "CONTACT"
                }
            }

            a.close-link {
                &::after {
                    content: "CLOSE"
                }
            }

            a.active {
                svg {
                    color: #0077B6;
                }
            }
        } 
        
        ul {
            position: absolute;
            bottom: 25px;
            width: 100%;
            display: block;
            padding: 0;
            list-style: none;
            text-align: center;
            margin: 0;

            li {
                a {
                    color: #cbd0d2;
                    padding: 9px 0; 
                    display: block;
                    font-size: 23px;
                    line-height: 16px;

                    &:hover svg {
                        color: #0077B6;
                    }
                }
            }
        }


}        

.hamburger-icon, .close-icon {
    display: none;
}

@media all and (max-width: 936px) {
    .nav-bar {
        background: transparent;
        width: 100%;
        height: 70px;
        background-color: #082d3a;
        min-height: auto;
        position: initial;
        

        ul, nav {
            display: none;
        }

        nav {

            background-color: #082d3a;
            // border: solid lime 5px;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: 0;
            position: fixed;
            z-index: 2;

            a {
                border: solid #90E0EF 2px;
                border-radius: 5px;
                height: 100px;
                font-size: 25px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        
            a:after {
                font-size: 20px;
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
                font-weight: 200;

            }
            
            a svg {
                opacity: 1 !important;
            }

            &.mobile-show{
                display: block;

            }
        }

        .hamburger-icon {
            display: block;
            color:  #90E0EF;
            // color: #0077B6;
            position: absolute;

            top: 15px;
            right: 15px;
        }

        .close-icon {
            display: block;
            cursor: pointer;
        }
    }
    

}