/* Apply a basic reset to remove default padding/margin */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    min-height: 100vh;
  }
  
  /* Set a maximum width for the main container */
  .contact-page {
    display: flex;
    // align-items: center;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
    grid-template-columns: 1em 1fr;
    // gap: 100px;
    // padding: 20px;

    h1 {
                    color: white;
                    font-family: 'Coolvetica';
                    font-size: 60px;
                    margin-bottom: 15px;
                    font-weight: 400;
                    margin-top: 0;
                    position: relative;
                    left: 10px;
        
                    &::before {
                        content: '';
                        position: absolute;
                        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                        font-size: 18px;
                        opacity: 0.6;
                        margin-top: -10px;
                        left: -10px;
                        line-height: 18px;
                    }
        
                    &::after {
                        content: '';
                        position: absolute;
                        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                        font-size: 18px;
                        left: -30px;
                        opacity: 0.6;
                        margin-top: -10px;
                        line-height: 18px;
                        bottom: -20px;
                        margin-left: 20px;
                    }
                }
        
                p {
                    font-size: 22px;
                    color: white;
                    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                    font-weight: 400;
                    min-width: fit-content;
                    animation: pulse 1.5s;
                    padding: 20px;
        
                    &:nth-of-type(1) {
                        animation-delay: 1s;
                    }
        
                    &:nth-of-type(2) {
                        animation-delay: 1.3s;
                    }
                    
                    &:nth-of-type(3) {
                        animation-delay: 1.6s;
                    }
                }
        
        
            .text-animate-hover {
                &:hover {
                    animation: rubberBand 0.5s;
                    color: #00B4D8;
                    cursor: pointer;
                }
            }
        
    
  
          
  /* Set the text area to be flexed vertically on smaller screens */
  .contact-text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    margin-left: 100px;
    width: auto;
  }
  
  /* Adjust font sizes and margins for smaller screens */

  
  /* Style the form inputs and textarea */
  form {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    
        ul {
            padding: 0;
            margin: 0;
    
            li {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
                position: relative;
                opacity: 0;
                animation: fadeInUp 2s 2s;
                animation-fill-mode: forwards;
                clear: both;
            }
    
            li.listHalf {
                width: 49%;
                margin-left: 2%;
                float: left;
                clear: none;
                
                &:first-child {
                    margin-left: 0;
                }
            }
        }
  }
  
  input[type="text"],
  input[type="email"]  
  textarea {
        font-size: 20px;
        resize: none;
        min-height: 25px;

        
        width: 100%;
        border: 0;
        height: 50px;
        background-color: #8fd3df;
        border-radius: 3px;
        color: #201e1e;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        padding: 0 20px; 
  }
  

  textarea {
    width: 100%;
    border: 0;
    padding: 10px;
    background-color: #8fd3df;
    border-radius: 3px;
    font-size: 16px;
    color: #201e1e;
    margin-bottom: 10px;
    box-sizing: border-box;
    resize: none;
    min-height: 150px;
    height: 200px;
    font-family: 'Open Sans', sans-serif;    
}


  /* Style the submit button */
    .submit-btn {
        background: 0 0;
        margin-top: 10px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: 'Coolvetica';
        font-size: 20px;
        font-weight: 300;
        color: #90E0EF; 
        border: 1px solid #90E0EF;
        border-radius: 5px;
        letter-spacing: 3px;
        text-decoration: none;
        animation: fadeIN 1s 1.9s backwards;
        white-space: nowrap;
        width: 100%;


        &:hover {
            font-weight: 300;
            background-color: #90E0EF;
            color: #333;
            border: 1px solid #90E0EF;
          }
    }

  
  /* Style the image container (if you decide to uncomment it) */
  .texas-img {
    display: grid;
    justify-self: center;
    align-items: center;
    margin-left: 100px;
    animation: fadeIn 1s 1.5s backwards;
    animation-delay: 1.5s;
  }
  
  .texas-img img {
    max-width: 100%;
    height: auto;
    border: #201e1e 5px solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  @media all and (max-width: 936px) {
    .contact-text-area {
      padding: 0;
      // right:10%;
      margin: 0;
    }

    h1 {
      font-size: 6rem;
      margin-bottom: 10px;
    }
    
    p {
      padding: 10px;
      font-size: 2.2srem;
      margin-bottom: 10px;
    }
    
    .a-tag {
      font-size: 1rem;
    }
    
    input[type="text"],
    textarea {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  @media all and (max-width: 936px) {


    .contact-text-area {
      padding-left: 20px;
      padding-right: 20px;
      // right:10%;
      // margin: 20px;
    }

    textarea {
      height: 100px;
    }
  }


  @media all and (max-width: 500px) {
    .texas-img {
      display: none;
    }

    body {
      overflow-y: scroll; /* Allow vertical scrolling */
    }
  }

}



  