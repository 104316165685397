.home-page {
  // max-width: 55%;
 
    .text-area {
        // margin: auto;
        // display: flex;
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        // width: 40%;
        max-height: 90%;
    }


    h1 {
        color: #fff;
        font-size: 56px;
        line-height: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
   
        // &:before {
        //   content: '<h1>';
        //   font-family: 'La Belle Aurore', cursive;
        //   color: #0077B6;
        //   font-size: 18px;
        //   position: absolute;
        //   margin-top: -40px;
        //   left: -15px;
        //   opacity: 0.6;
        // }
   
        // &:after {
        //   content: '<h1/>';
        //   font-family: 'La Belle Aurore', cursive;
        //   color: #1c81b4;
        //   font-size: 18px;
        //   position: absolute;
        //   margin-top: 18px;
        //   margin-left: 20px;
        //   animation: fadeIn 1s 1.7s backwards;
        //   opacity: 0.6;
        // }
    }


  h2 {
    color: #0a4a6c;
    font-family: 'Coolvetica';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 3.5px;
    animation: fadeIn 1s 1.8s backwards;
  }


  .contact-btn {
    font-family: 'Coolvetica';
    font-size: 20px;
    color: #90E0EF;
    font-weight: 300;
    letter-spacing: 3px;
    text-decoration: none;
    border: 2px solid #90E0EF;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 25px;
    float: left;
    animation: fadeIN 1s 1.9s backwards;
    white-space: nowrap;


    &:hover {
      font-weight: 300;
      background-color: #90E0EF;
      color: #333;
    }
  }

  .resume-btn {
    cursor: pointer;
    font-family: 'Coolvetica';
    font-size: 20px;
    background-color: transparent;
    color: #CAF0F8;
    font-weight: 300;
    letter-spacing: 3px;
    text-decoration: none;
    border: 2px solid #CAF0F8;
    border-radius: 5px;
    padding: 12.5px 20px;
    margin-top: 25px;
    margin-left: 25px;
    float: left;
    animation: fadeIN 1s 1.9s backwards;
    white-space: nowrap;


    &:hover {
      font-weight: 300;
      background-color: #CAF0F8;
      color: #03045E;
      border: 1px solid #CAF0F8;
    }
  }

  .spinning-photo {
    bottom: 0;
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    height: 100%;
    max-height: 90%;
    animation: fadeIn 1s 1.5s backwards;
    animation-delay: 1.5s;
  }


  .photos {
    left: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: spinFace;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    transform-style: preserve-3d;
    margin-left: calc(50% - 100px);
    // margin-top: 180px;


    div {
        display: flex;
        position: absolute;
        height: 600px;
        width: 500px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 15px #51c7df;
        border-radius: 60%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }


    .me {
      transform: translateZ(0.1px);
      img {
        border-radius: 60%;
        border: 3px solid #fff;
        overflow: hidden;
        object-fit: cover;
        // object-position: top;
        height: 700px;
        width: 500px;
      }
    }

    .austin {
      img {
        border-radius: 60%;
        border: 3px solid #fff;
        overflow: hidden;
        height: 700px;
        width: 500px;
      }

    }

    @media (max-width: 1700px) {

 
      h1 {
        font-size: 50px;
      }
    
    
      h2 {
        font-size: 16px;
        width: 80%; /* Set a specific width for the h2 element */
        // margin-left: auto; /* Push the h2 to the right */
        margin-right: auto; 
      }
    
    }


    @media all and (max-width: 1600px) {
      .home-page {
        position: initial;
      }
    
      .container {
        position: initial;

      }
    }

    @media (max-width: 1600px) {
      .photos {
        left: 10%;
      }
      .me img,
      .austin img, div {
        width: 425px;
        height: 600px; /* Adjust the aspect ratio for smaller screens */
      }
    }

    @media (max-width: 1350px) {
      .photos {
        left: 10%;
      }
      .me img,
      .austin img, div {
        width: 350px;
        height: 500px; /* Adjust the aspect ratio for smaller screens */
      }
    }

    @media (max-width: 1100px) {

      .me img,
      .austin img, div {
        display: none;/* Adjust the aspect ratio for smaller screens */
      }
    }

    /* Media Query for smaller screens */
    @media (max-width: 768px) {

      .me img,
      .austin img, div {
        width: 400px;
        height: 500px; /* Adjust the aspect ratio for smaller screens */
      }

      .resume-btn {
        margin-right: 10px;
      }
    
    }
   
    @keyframes spinFace {
      from,
     
      to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      }
      50% {
        transform: rotateY(180deg);
      }
    }
 
  }

  @media (max-width: 1402px) {

  
    h1 {
      font-size: 45px;
    }
      
    h2 {
      font-size: 14px;
      width: 80%; /* Set a specific width for the h2 element */
      // margin-left: auto; /* Push the h2 to the right */
      margin-right: auto; 
    }
    .home-page {
      overflow-y: auto; /* Enable vertical scrolling */
    }
  }

  @media all and (max-width: 500px) {

    .text-area {
      left: 0;
      padding: 20px;
    }

    h1 {
      font-size: 40px;
    }

    br {
      display: none;
    }

    .resume-btn {
      margin-left: 0;
    }

    .me img,
    .austin img, div {
      display: block;/* Adjust the aspect ratio for smaller screens */
    }
  }
}




// @media (max-width: 1250px) {
//   .text-area {
//     width: 45%;
//     height: 150px;
//   }

//   h1 {
//     font-size: 32px;
//   }
 
//   .resume-btn {
//     // width: 200px;
//     font-size: 16px;
//   }

//   .contact-btn {
//     font-size: 17px;
//   }
// }

// @media (max-width: 1000px) {
//   .text-area {
//     width: 45%;
//     height: 150px;
//   }

//   h1 {
//     font-size: 28px;
//   }
 
//   .resume-btn {
//     // width: 200px;
//     font-size: 16px;
//   }

//   .contact-btn {
//     font-size: 17px;
//   }
// }


