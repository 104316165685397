.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
        bottom: auto;
        top: 35px;
    }

.tags {
        color: #1c81b4;
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        left: 120px;
        font-size: 18px;
        font-family: 'La Belle Aurore', cursive;
    }

 .bottom-tag-html {
        margin-left: -20px;
    }

.container {
        width: 100%;
        will-change: contents;
        height: 90%;
        min-height: 566px;
        position: absolute;
        opacity: 0;
        top: 5%;
        margin: 0 auto;
        z-index: 1;
        transform-style: preserve-3d;
        animation: fadeIn 1s forwards;
        animation-delay: 1s;
}


    .text- {
        position: absolute;
        left: 10%;
        top: 45%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            color: white;
            font-family: 'Coolvetica';
            font-size: 60px;
            margin-bottom: 15px;
            font-weight: 400;
            margin-top: 0;
            // position: relative;
            left: 10px;

            &::before {
                content: '';
                position: absolute;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size: 18px;
                opacity: 0.6;
                margin-top: -10px;
                left: -10px;
                line-height: 18px;
            }

            &::after {
                content: '';
                position: absolute;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size: 18px;
                left: -30px;
                opacity: 0.6;
                margin-top: -10px;
                line-height: 18px;
                bottom: -20px;
                margin-left: 20px;
            }
        }

        p {
            font-size: 22px;
            color: white;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-weight: 400;
            min-width: fit-content;
            animation: pulse 1.5s;

            &:nth-of-type(1) {
                animation-delay: 1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.3s;
            }
            
            &:nth-of-type(3) {
                animation-delay: 1.6s;
            }
        }
    }


    .text-animate-hover {
        &:hover {
            animation: rubberBand 0.5s;
            color: #00B4D8;
            cursor: pointer;
        }
    }
